import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import Sidebar from "../components/Sidebar";
import { Select } from "../components/Core";
import axios from "axios";
import axiosConfig from '../axiosConfig';
import { useFilterSearch } from '../context/FilterSearchContext';
import GlobalContext from "../context/GlobalContext";


import iconL from "../assets/image/svg/icon-loaction-pin-black.svg";
import iconS from "../assets/image/svg/icon-suitecase.svg";
import iconC from "../assets/image/svg/icon-clock.svg";
import network from "../../constants/Network";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark as faBookmarkRegular } from '@fortawesome/free-regular-svg-icons';
import { faBookmark as faBookmarkSolid } from '@fortawesome/free-solid-svg-icons';
import { faHospitalAlt } from '@fortawesome/free-solid-svg-icons';
import { borderRadius, height } from "styled-system";

const defaultCountries = [
  { value: "sp", label: "Singapore" },
  { value: "bd", label: "Bangladesh" },
  { value: "usa", label: "United States of America" },
  { value: "uae", label: "United Arab Emirates" },
  { value: "pk", label: "Pakistan" },
];

const RecommendedJobListing = () => {

  const [showMore, setShowMore] = useState([]);

  const toggleShowMore = (id) => {
    if (showMore.includes(id)) {
      setShowMore(showMore.filter(i => i !== id));
    } else {
      setShowMore([...showMore, id]);
    }
  };

  const imageBaseUrl = `${network.serverip}/images/`;

  const [search, setSearch] = useState('');
  const [count, setCount] = useState(10);
  const [totalJobCount, setTotalJobCount] = useState(0);
  const [resultsFor, setResultsFor] = useState('');
  const isMountedRef = useRef(false);
  const [jobs, setJobs] = useState([]);
  const [userCoordinates, setUserCoordinates] = useState(null);
  const gContext = useContext(GlobalContext);
  const { user, coordinates } = useContext(GlobalContext);
  const [showMenuJobId, setShowMenuJobId] = useState(null);
  const [message, setMessage] = useState('');

  const [userId, setUserId] = useState('');

  const toggleMenuButton = (id) => {
    setShowMenuJobId(showMenuJobId === id ? null : id)
  }

  const handleSearch = async (event) => {
    event.preventDefault();
    setCount(10);
    setShowMore([]);
    await fetchRecommendedJobs(search, 10);
  };

  const handleLoadMore = async () => {
    try {
      const newCount = count + 10;
      setCount(newCount);
      await fetchRecommendedJobs(search, newCount);
    } catch (error) {
      console.error('Error loading more jobs:', error);
    }
  };

  const fetchRecommendedJobs = async (search, count) => {
    try {

      setMessage('');

      if (!userId) {
        console.log('No user found for job fetching');
        return
      }

      const response = await axiosConfig.post('/recommendedjoblist-forweb', { search, count, userCoordinates: userCoordinates, userId });

      if (response.data.success && isMountedRef.current) {

        setJobs(response.data.data);
        setTotalJobCount(response.data.total);
        setResultsFor(search);
        setMessage(response.data.message);

      } else if (!response.data.success) {

        if (isMountedRef.current) {
          setJobs([]);
          setTotalJobCount(0);
          setResultsFor(search);
        }
        console.error('Failed to fetch jobs');
      }
    } catch (error) {
      if (isMountedRef.current) {
        setJobs([]);
        setTotalJobCount(0);
        setResultsFor(search);
      }
      console.error('Error fetching jobs:', error);
    }
  };

  const handleJobSaveOrUnsave = async (jobId) => {
    try {

      if (!userId) {
        gContext.toggleSignInModal();

        return
      }

      if (userId && jobId) {
        const response = await axiosConfig.post(`/saveOrUnsaveJob-web`, { userId, jobId });

        if (response.data.success && isMountedRef.current) {

          setJobs((prevState) =>
            prevState.map((job) =>
              job.id === jobId
                ? { ...job, isSaved: job.isSaved === 1 ? 0 : 1 }
                : job
            )
          );

          console.log('Job saved / unsaved');
        } else {
          if (isMountedRef.current) {
            console.log('Failed to save / unsave job');
          }
          console.error('Failed to save / unsave job:', response.data.message);
        }
      } else {
        console.error('userId or jobId is missing');
      }
    } catch (error) {
      console.error('Failed to save/ unsave job:', error);
    }
  };

  useEffect(() => {
    if (user && user.id) {
      setUserId(user.id)
    }
  }, [user]);

  useEffect(() => {
    if (coordinates) {
      setUserCoordinates(coordinates)
    }
  }, [coordinates]);

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchRecommendedJobs(search, count);
    };

    fetchData();
  }, [userId, userCoordinates]);

  const styles = RecommendedJobListScreenStyling()

  return (
    <>
      <PageWrapper>
        <div className="bg-default-1 pt-32 pt-lg-28 pb-13 pb-lg-25">
          <div className="container">
            <div className="row">
              {/* <div className="col-12 col-lg-4 col-md-5 col-xs-8">
                <Sidebar />
              </div> */}
              {/* <!-- Main Body --> */}

              {/* className="col-12 col-xl-8 col-lg-8" */}
              <div className="col-12">
                {/* <!-- form --> */}
                <form className="search-form" onSubmit={handleSearch}>
                  <div className="filter-search-form-2 search-1-adjustment bg-white rounded-sm shadow-7 pr-6 py-6 pl-6">
                    <div className="filter-inputs">
                      <div className="form-group position-relative w-lg-45 w-xl-40 w-xxl-45">
                        <input
                          className="form-control focus-reset pl-13"
                          type="text"
                          id="search"
                          value={search}
                          onChange={(event) => setSearch(event.target.value)}
                          placeholder="Search"
                        />
                        <span className="h-100 w-px-50 pos-abs-tl d-flex align-items-center justify-content-center font-size-6">
                          <i className="icon icon-zoom-2 text-primary font-weight-bold"></i>
                        </span>
                      </div>
                      {/* <!-- .select-city starts --> */}
                      {/* <div className="form-group position-relative w-lg-55 w-xl-60 w-xxl-55">
                        <Select
                          options={defaultCountries}
                          className="pl-8 h-100 arrow-3 font-size-4 d-flex align-items-center w-100"
                          border={false}
                        />
                        <span className="h-100 w-px-50 pos-abs-tl d-flex align-items-center justify-content-center font-size-6">
                          <i className="icon icon-pin-3 text-primary font-weight-bold"></i>
                        </span>
                      </div> */}
                      {/* <!-- ./select-city ends --> */}
                    </div>
                    <div className="button-block">
                      <button
                        className="btn btn-primary line-height-reset h-100 btn-submit w-100 text-uppercase"
                        type="submit"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </form>
                <div className="pt-12">
                  <div className="d-flex align-items-center justify-content-between mb-6">
                    {resultsFor ? (
                      <h5 className="font-size-4 font-weight-normal text-gray">
                        <span className="heading-default-color">{totalJobCount}</span>
                        {totalJobCount === 1
                          ? ` result for `
                          : ` results for `}
                        <span className="heading-default-color mr-2">{resultsFor}</span>
                        {message &&
                          <span className="font-size-4 font-weight-bold text-gray">
                            ( {message} )
                          </span>}
                      </h5>
                    ) : (
                      <h5 className="font-size-4 font-weight-normal text-gray">
                        <span className="heading-default-color mr-2">{totalJobCount}</span>
                        {" "} {"Job(s) found"} {" "}
                        {message &&
                          <span className="font-size-4 font-weight-bold text-gray">
                            ( {message} )
                          </span>}
                      </h5>
                    )
                    }
                    <div className="d-flex align-items-center result-view-type">
                      <Link
                        to="/recommended-joblisting"
                        className="heading-default-color pl-5 font-size-6 hover-text-hitgray active"
                      >
                        <i className="fa fa-list-ul"></i>
                      </Link>
                      <Link
                        to="/recommended-joblisting-grid"
                        className="heading-default-color pl-5 font-size-6 hover-text-hitgray"
                      >
                        <i className="fa fa-th-large"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="mb-8">
                    {jobs.map((job, index) => (
                      <div className="pt-9 px-xl-9 px-lg-7 px-7 pb-7 light-mode-texts bg-white rounded hover-shadow-3 " style={{ marginBottom: "20px" }} key={index}>
                        <div className="row media align-items-center">
                          <div className="col-12 d-flex align-items-center">
                            <div className="flex-shrink-0">
                              <button
                                onClick={() => gContext.toggleImageViewModal(job?.image)}
                                style={styles.imageViewButton}
                              >
                                <div className="square-72 d-flex align-items-center" style={styles.imageContainer}>
                                  <img
                                    src={job && job.image ? imageBaseUrl + job.image : ""}
                                    alt=""
                                    style={styles.jobImage}
                                  />
                                </div>
                              </button>
                            </div>
                            <div className="flex-grow-1 ml-3">
                              <div className="w-100 d-flex justify-content-between align-items-center">
                                <div className="flex-grow-1 ml-3">
                                  <h3 className="mb-0">
                                    <Link to={`/job-details/?id=${job.id}`}
                                      className="font-size-6 heading-default-color">
                                      {job && job.title ? job.title : ''}
                                    </Link>
                                  </h3>
                                </div>
                                <div className="flex-shrink-0 d-flex text-center align-items-center">
                                  {job?.created_at && (
                                    <div className="text-right">
                                      <p className="font-size-3 mb-0" style={styles.createdDateBox}>
                                        {new Date(job.created_at).toLocaleDateString('default', {
                                          day: '2-digit',
                                          month: 'short',
                                          year: 'numeric'
                                        }).replace(/ /g, '-')}
                                      </p>
                                    </div>
                                  )}
                                </div>
                                {showMenuJobId === job?.id &&
                                  <>
                                    {job?.isReported === 1 ?
                                      (
                                        <button
                                          style={styles.reportButton}
                                          className="font-size-4 text-gray"
                                        >
                                          <i className="fas fa-info-circle text-gray"></i>
                                          <span className="text-gray ml-5">Already Reported</span>
                                        </button>
                                      ) : (
                                        <button
                                          onClick={() => { gContext.toggleReportJobModal(job?.id) }}
                                          style={styles.reportButton}
                                          className="font-size-4 text-danger"
                                        >
                                          <i className="fas fa-info-circle text-danger"></i>
                                          <span className="text-danger ml-5">Report</span>
                                        </button>
                                      )
                                    }
                                  </>
                                }

                                <button
                                  onClick={() => { toggleMenuButton(job?.id) }}
                                  style={styles.openMenuIcon}
                                >
                                  <i className="fas fa-ellipsis-v ml-5"></i>
                                </button>

                              </div>

                              <ul className="d-flex list-unstyled flex-wrap align-items-center">
                                <li className="mt-2 mr-8 font-size-3 font-weight-bold text-black-2 d-flex align-items-center">
                                  <span className="mr-2">
                                    <FontAwesomeIcon icon={faHospitalAlt} />
                                  </span>
                                  <span className="">
                                    {job && job.institution && job.institution.title ? job.institution.title : ''}
                                  </span>
                                </li>
                                <li className="mt-2 font-size-3 d-flex align-items-center">
                                  <span className="mr-2 d-flex align-items-center">
                                    <img src={iconS} alt="" style={{ height: '1.2em', width: 'auto' }} />
                                  </span>
                                  <span className="">
                                    {job && job.job_type && job.job_type.title ? job.job_type.title : ''}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* {job?.salary && job.salary > 0 && (
                            <div className="col-md-3 text-right pt-7 pt-md-5">
                              <div className="media justify-content-md-end">
                                <p className="font-weight-bold font-size-3 mb-0" style={styles.salaryBox}>
                                  <span className="mr-2">₹</span>
                                  <span className="text-black-2">{`${job.salary}/month`}</span>
                                </p>
                              </div>
                            </div>
                          )} */}
                        <div className="row pt-2">
                          <div className="col-md-12">
                            <p className="mb-2">
                              {job && job.description ? (
                                <span className="font-size-4 text-black-2">
                                  {((showMore.includes(job.id)) || job.description.length <= 150)
                                    ? `${job.description} `
                                    : `${job.description.slice(0, 150)}... `}
                                  {job.description.length > 150 && (
                                    // <span onClick={() => toggleShowMore(job.id)} style={styles.showMoreButton}>
                                    //   {showMore.includes(job.id) ? 'Show less' : 'Show more'}
                                    // </span>
                                    <Link to={`/job-details/?id=${job.id}`} style={styles.showMoreButton}>
                                      {'Show more'}
                                    </Link>
                                  )}
                                </span>
                              ) : (
                                null
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="w-100">
                          {job?.posted_by?.name && (
                            <p className="font-size-3 text-gray mb-0">
                              {`Posted by: ${job.posted_by.name}`}
                            </p>
                          )}
                        </div>
                        <div className="row pt-4">
                          <div className="col-md-12 d-flex justify-content-between align-items-center">
                            <p className="font-size-4 text-black-2">
                              <span className="mr-4">
                                <img src={iconL} alt="" />
                              </span>
                              <span className="font-weight-semibold">
                                {job && job.location && job.location.title ? job.location.title : ''}
                                {job && job.state && job.state.title ? `, ${job.state.title}` : ''}
                              </span>
                            </p>
                            <div>
                              {/* <FontAwesomeIcon
                                icon={job && job.isSaved === 1 ? faBookmarkSolid : faBookmarkRegular}
                                size="2x"
                              /> */}
                              {job && job.isSaved && job.isSaved === 1 ? (
                                <button
                                  onClick={() => handleJobSaveOrUnsave(job?.id)}
                                  className="btn text-uppercase h-px-48 rounded-3 mb-5 px-5">
                                  <i className="fas fa-bookmark font-weight-bold mr-4 font-size-4 text-success"></i>{" "}
                                  <span className="text-success">Saved</span>
                                </button>
                              ) : (
                                <button
                                  onClick={() => handleJobSaveOrUnsave(job?.id)}
                                  className="btn text-black-2 text-uppercase h-px-48 rounded-3 mb-5 px-5">
                                  <i className="icon icon-bookmark-2 font-weight-bold mr-4 font-size-4"></i>{" "}
                                  Save job
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="text-center pt-5 pt-lg-13">
                    {/* <Link
                      to="/#"
                      className="text-green font-weight-bold text-uppercase font-size-3"
                    >
                      Load More <i className="fas fa-sort-down ml-3"></i>
                    </Link> */}
                    {jobs.length > 0 && totalJobCount > jobs.length ? (
                      <button onClick={handleLoadMore}
                        className="btn btn-green text-uppercase btn-medium rounded-3"
                      >
                        <span>
                          Load More <i className="fas fa-arrow-down ml-2"></i>
                        </span>
                      </button>
                    ) : null}

                    {jobs.length === 0 && (
                      <span className="heading-default-color font-weight-bold font-size-3">
                        No jobs found
                      </span>
                    )}
                  </div>
                </div>
                {/* <!-- form end --> */}
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );


};
export default RecommendedJobListing;


function RecommendedJobListScreenStyling() {
  const styles = {
    showMoreButton: {
      display: 'inline-block',
      background: 'none',
      border: 'none',
      color: 'blue',
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    imageContainer: {
      backgroundColor: '#f5f5f5',
      aspectRatio: '4 / 3',
      overflow: 'hidden',
    },
    jobImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    saveButton: {
      border: 'none',
      backgroundColor: 'transparent',
    },
    // loadMoreButton: {
    //   border: 'none',
    //   background: 'none',
    // },
    salaryBox: {
      backgroundColor: '#f0f0f0',
      padding: '5px',
      borderRadius: '5px',
    },
    createdDateBox: {
      backgroundColor: '#f0f0f0',
      padding: '5px',
      borderRadius: '5px',
    },
    imageViewButton: {
      outline: 'none',
      border: 'none',
      background: 'none',
      padding: '0',
    },

    openMenuIcon: {
      background: 'none',
      border: 'none',
      color: 'inherit',
      cursor: 'pointer',
      outline: 'none',
    },

    reportButton: {
      position: 'absolute',
      right: '2.9rem',
      width: 'auto',
      height: 'auto',
      background: 'white',
      border: '1px solid',
      borderRadius: '5px',
      paddingHorizontal: '6px',
      paddingVertical: '6px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      outline: 'none',
    }
  };

  return styles;
}
